@use 'plantdb/theme';

$-font-path: '../../assets/fonts';

@font-face {
  font-family: theme.$font-family-icons;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('#{$-font-path}/Material-Icons.woff2') format('woff2');
}

@font-face {
  font-family: theme.$font-family-regular;
  font-style: normal;
  font-display: swap;
  src: url('#{$-font-path}/Roboto-Regular.woff2') format('woff2');
}

// --- Non VF
@supports not (font-variation-settings: normal) {

  @font-face {
    font-family: theme.$font-family-title;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('#{$-font-path}/Bitter-400.woff2') format('woff2');
  }

  @font-face {
    font-family: theme.$font-family-title;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('#{$-font-path}/Bitter-700.woff2') format('woff2');
  }
}

// --- VF (variable font)
@supports (font-variation-settings: normal) {
  @font-face {
    font-family: theme.$font-family-title;
    font-weight: 100 900;
    font-display: swap;
    src: url('#{$-font-path}/Bitter-VF.woff2') format('woff2 supports variations'),
    url('#{$-font-path}/Bitter-VF.woff2') format('woff2-variations');
  }
}
