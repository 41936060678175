@use '@angular/material' as mat;
@use 'plantdb/theme';
/**
 * override angular material components
 */

 :root {
  // Overrides the form-fields label default size with v18 it increased
  --mdc-filled-text-field-label-text-size: 14px;
 }

 // to place it in the toolbaer-menu, and both are higher then the leaflet map layers
.cdk-overlay-container{
  z-index: 2000 !important;
}

.mdc-dialog__content {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 1rem !important;
  max-height: 75vh !important;
}

.mat-mdc-dialog-title {
  .title {
    font-size: 1.5rem;
    margin: 20px 0 10px;
    line-height: 1.5rem;
  }

  .subtitle {
    font-size: 0.75rem;
    color: mat.m2-get-color-from-palette(theme.$primary, 500);
    line-height: 0.75rem;
  }
}


/// ------- Material Components Tweaks -------

.mat-mdc-input-element {
  max-width: 200px;
}

.mat-icon {
  user-select: none;
}

.mat-subtitle-1 {
  @include mat.m2-typography-level(theme.$secondary-typography, subtitle-1);
  font-variation-settings: 'wght'500;
  color: mat.m2-get-color-from-palette(theme.$primary, 500);
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 56px;
}


div.mat-mdc-select-panel.large-panel {
  max-height: 420px;
}