@use '@angular/material' as mat;
@use 'plantdb/theme';

/// ------- Material Config -------

@include mat.core();
@include mat.all-component-themes(theme.$colors);
@include mat.typography-hierarchy(theme.$default-typography);

/// ------- Site Styles -------

:root {
  --toolbar-height: 48px;
  --content-margin: 1rem;
  --content-padding: 1rem;
  --content-min-margin: 1rem;

  --content-max-width: 1340px;
  --breakpoint-max-width: 1380px;

  --card-width: 210px;    // catalogue plant list, and possible for other elements, too
  --gap: 0.75rem;   // catalogue plant list
  --gap-small: 0.25rem;   // other lists

  @media only screen and (min-width: theme.$breakpoint-tablet) {
    --content-margin: 3rem;
    --content-padding: 2rem;
    --content-min-margin: 3rem;
  }
}

*:focus {
  outline: none;
  border: none;
}

*::selection {
  background: mat.m2-get-color-from-palette(theme.$primary, 900);
  color: mat.m2-get-color-from-palette(theme.$accent);
}

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: subpixel-antialiased;
}

body {
  @include mat.m2-typography-level(theme.$default-typography, body-1);
  font-optical-sizing: auto;
  background: mat.m2-get-color-from-palette(theme.$primary, 100);
  //TODO -webkit-tap-highlight-color: mat-color(theme.$primary,900);
}

main {
  overflow-x: hidden;
}

article {
  margin-bottom: 4rem;
}

/**
 * Wrapper element around the main content
 * Positions the conteten by setting up margin.
 * Margin collapses for small screens.
 */
.page-inner {
  margin: 0 var(--content-min-margin);
  // overflow-x: hidden;
  @media only screen and (min-width: theme.$breakpoint-max) {
    max-width: var(--content-max-width);
    margin-left: auto;
    margin-right: auto;
  }

  &.main {
    padding-bottom: 1rem;
  }
}

// Margin for first page-inner element of main
main .page-inner:first-child {
  margin-top: var(--content-margin);
}

caption {
  @include mat.m2-typography-level(theme.$secondary-typography, caption);
}

a {
  color: mat.m2-get-color-from-palette(theme.$primary, 900);
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  @include mat.m2-typography-level(theme.$secondary-typography, headline-5);
  color: mat.m2-get-color-from-palette(theme.$primary, 900);
  // white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
}

h1 {
  font-size: 4em;
  letter-spacing: -0.02em;
}

h2 {
  font-size: 3em;
  letter-spacing: -0.002em;
}

h3 {
  font-size: 2em;
  letter-spacing: 0;
  margin: 0.5em 0 ;
}

h4 {
  font-size: 1em;
  letter-spacing: 0;
}

main {
  a {
    text-decoration: none;
    border-bottom: 1px solid mat.m2-get-color-from-palette(theme.$primary, 500);
    padding-bottom: 1px;
    transition: all .1s;
    &:hover {
      background: mat.m2-get-color-from-palette(theme.$primary, 200);
      transition: all .05s;
    }
  }
}


.meta {
  *::selection {
    background: var(--color-black);
    color: var(--color-white);
  }
}

/// -----

.material-icons {
  font-family: theme.$font-family-icons;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}


/// ------- Material Typography Tweaks -------

.mat-headline-1 {
  font-variation-settings: 'wght'600;
}

.mat-headline-2 {
  font-variation-settings: 'wght'650;
}

.mat-headline-3 {
  font-variation-settings: 'wght'650;
}

.mat-headline-4 {
  font-variation-settings: 'wght'100;
}

.mat-caption {
  font-variation-settings: 'wght'400;
  //text-transform: uppercase;
}

// plant-species sub-header
.mat-subtitle-1 {
  font-variation-settings: 'wght'400;
  @include mat.m2-typography-level(theme.$secondary-typography, headline-4);
  font-size: 11pt;
}


// Material card
// NOTE: this needs to placed after the material theme import
.mat-mdc-card {
  backface-visibility: hidden;
  outline: none;
  padding: 0 !important;
  @include mat.elevation(8);

  .mat-mdc-card-header {
    padding: 0;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  .mat-card-header-text {
    margin: 0;
  }

  .mat-mdc-card-title {
    @include mat.m2-typography-level(theme.$secondary-typography, headline-4);
    font-variation-settings: 'wght'650;
  }

  .mat-mdc-card-subtitle {
    @include mat.m2-typography-level(theme.$secondary-typography, subtitle-1);
    font-variation-settings: 'wght'500;
  }
}
