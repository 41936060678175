@use '@angular/material' as mat;
@use 'plantdb/theme';

$scrollbar-thick: 0.8vw !default;
$scrollbar-min-thick: 6px !default;
$scrollbar-max-thick: 12px !default;
$scrollbar-track-color: mat.m2-get-color-from-palette(theme.$primary, 300) !default;
$scrollbar-thumb-color: mat.m2-get-color-from-palette(theme.$primary, 400) !default;
$scrollbar-thumb-color-hover: mat.m2-get-color-from-palette(theme.$primary, 500) !default;

::-webkit-scrollbar {
  border: none;

  &:vertical {
    width: $scrollbar-thick;
    min-width: $scrollbar-min-thick;
    max-width: $scrollbar-max-thick;
  }

  &:horizontal {
    display: none;
  }
}

::-webkit-scrollbar-track {
  background: $scrollbar-track-color;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb-color;

  &:hover {
    background: $scrollbar-thumb-color-hover;
  }
}
