@use '@angular/material' as mat;
@use './theme';

@mixin overlay-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: mat.m2-get-color-from-palette(theme.$primary, 800);
}

@mixin tiny-icon {
  width: 13px;
  font-size: 10px;
  line-height: 14px;
}

@mixin basic-grid {
  display: grid;
  gap: 1em;

  grid-template-columns: 33% 33% 33%;

  @media (max-width: theme.$breakpoint-inter) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: theme.$breakpoint-tablet) {
    grid-template-columns: 1fr;
  }

  > div {
    @include mat.elevation(1);

    overflow-x: hidden;

    .inner-inline {
      display: flex;
    }
    div.image[routerLink] {
      cursor:pointer;
    }
  }

  .button-block {
    width: 100%;
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

//
// Global mix-ins to produce emphasised block elements like mat-card
//
@mixin accent-block-base {
  padding: 1em;
  background: mat.m2-get-color-from-palette(theme.$accent, 100);

  border: solid lightgray 1px;
  border-radius: 3px;

  // Simpler elevation + hover effect (not using focus-transition mixin):
  @include mat.elevation(1);

  &:hover {
    // border: solid gray 1px;
    @include mat.elevation(2);
  }
}
@mixin accent-block {
  @include accent-block-base();
  background: mat.m2-get-color-from-palette(theme.$accent, 100);
}

@mixin focus-card($scale: 1.01, $elevation: 10) {
  background: #fff; //mat-color($theme-primary,0);
  @include mat.elevation($elevation);
  transform: scale($scale);
  backface-visibility: hidden;
  z-index: 50;
  border: 1px solid mat.m2-get-color-from-palette(theme.$primary, 600);
}

@mixin focus-transition($time, $ease: ease-in-out) {
  transition:
    background $time $ease,
    box-shadow $time $ease,
    color $time $ease,
    transform $time $ease,
    border $time $ease;
}