mat-form-field.fullsize , button.fullsize{
  width: 100%;
}

p.below-form, div.below-form {
  margin-top: 2.5rem;
}

form {
  max-width: var(--limit-width);
}
