@use '@angular/material' as mat;
@use 'plantdb/theme';

.plant-data-rows {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  label {
    display: block;
    text-transform: uppercase;
  }

  .data-row {
    display: flex;
    margin: 0.25em 0;

    >* {
      margin-right: 0.75em;
    }

    &.full-width {
      width: 100%;
    }
  }

  div span {
    margin: .2em 0;
    display: block;
  }
}


.plant-grid-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--gap);
  padding-bottom: 4rem;
  width: 100%;

  @media only screen and (min-width: theme.$breakpoint-tablet) {
    grid-template-columns: repeat(auto-fit, var(--card-width));
  }
}


h2.smallheader,
h3.smallheader {
  @include mat.m2-typography-level(theme.$secondary-typography, headline-4);
}


// CARD stylings
// Site-wide styles for mat-card and similar uses

// Wrapper for image of fixed size (no scaling)
// Override height / max-height in the specific component
div.fixed-size-image {
  position: relative;
  overflow: hidden;

  img.thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    user-select: none;

    @media only screen and (max-width: theme.$breakpoint-tablet) {
      // phone and tablet image height
      max-height: 20vh;
    }

    // desktop image height
    max-height: 160px;
  }
}